<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      Kosten
    </h1>

    <div class="mb-4">
      <button @click="handleToevoegen">
        + Kosten toevoegen
      </button>
    </div>

    <div class="py-4 flex flex-col">
      <h2 class="bg-dashboard-dark text-white mb-0 px-2 py-1">
        Filter
      </h2>
      <div class="flex flex-col lg:flex-row gap-2 lg:gap-4 px-2 mt-2">
        <UISelectWagen
          :key="filters.wagen_id"
          v-model="filters.wagen_id"
          class="max-w-sm"
          nullable
          label
        />
        <UISelectChauffeur
          :key="filters.user_id"
          v-model="filters.user_id"
          class="max-w-sm"
          nullable
          label
        />
        <UISelectDataOptions
          v-model="filters.type"
          nullable
          label="Type"
          options-table="options_kosten_types"
        />
        <UISelectFixed
          v-model="filters.soort"
          all
          label="Soort"
          type="KOSTEN_SOORT"
          placeholder="Selecteer ..."
        />
        <UISelectDataOptions
          v-model="filters.locatie"
          class="max-w-xs"
          nullable
          label="Kosten locatie"
          options-table="options_kosten_locaties"
        />
      </div>

      <div class="flex flex-col lg:flex-row gap-2 lg:gap-4 px-2 mt-2">
        <UISelectStatsFilter
          v-model:type="filters.type_datum"
          v-model:day="filters.day"
          v-model:month="filters.month"
          v-model:year="filters.year"
          v-model:user_id="filters.user_id"
        />
      </div>
    </div>

    <div class="py-4">
      <UITableResponsive :items="items" :headers="['Datum', 'Bedrag', 'Type', 'Soort', 'Locatie', 'Chauffeur', 'Wagen', 'Opmerking', 'Aangemaakt_Door', 'Acties']">
        <template #item-Datum="{ item }">
          <span class="block">{{ dateStringLocale(item.date) }}</span>
        </template>
        <template #item-Bedrag="{ item }">
          &euro;&nbsp;{{ Number(item.bedrag || 0).toFixed(2) }}
        </template>
        <template #item-Type="{ item }">
          {{ item.type || 'ANDERE' }}
        </template>
        <template #soort-Type="{ item }">
          {{ item.soort || '?' }}
        </template>
        <template #item-Locatie="{ item }">
          <div v-if="item.locatie">
            {{ item.locatie }}
          </div>
          <small v-else class="block italic">Geen locatie</small>
        </template>
        <template #item-Chauffeur="{ item }">
          <div v-if="item.user_id">
            {{ store.getters.chauffeur(item.user_id)?.label }}
          </div>
          <small v-else class="block italic">Geen chauffeur</small>
        </template>
        <template #item-Wagen="{ item }">
          <div v-if="item.wagen_id">
            {{ store.getters.wagen(item.wagen_id)?.label }}
          </div>
          <small v-else class="block italic">Geen wagen</small>
        </template>
        <template #item-Opmerking="{ item }">
          <ButtonAlertOpmerking :opmerking="item?.opmerking" />
          <small v-if="item.id" class="block italic"><b>ID:</b> #{{ item.id }}</small>
          <small v-if="item.nordigen_uuid" class="block italic"><b>UUID:</b> {{ item.nordigen_uuid }}</small>
        </template>
        <template #item-Aangemaakt_Door="{ item }">
          {{ store.getters.chauffeur(item.creator_id)?.label }}
        </template>
        <template #item-Acties="{ item }">
          <div v-if="store.getters.hasKantoorPermission" class="flex flex-col gap-2 w-32">
            <button
              class="btn warning text-white h-8"
              @click="handleEdit(item)"
            >
              <i class="fas fa-edit"></i> Bewerken
            </button>
            <button
              class="btn error text-white h-8"
              @click="handleVerwijderen(item)"
            >
              <i class="fas fa-trash"></i> Verwijderen
            </button>
          </div>
        </template>
      </UITableResponsive>

      <UITablePagination
        v-model:limit="limit"
        v-model:offset="offset"
        class="mt-2"
        :count="count"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, unref } from 'vue'
import { useStore } from 'vuex'
import clone from 'just-clone'

import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UISelectDataOptions from '@/components/UI/Select/SelectDataOptions.vue'
import UISelectStatsFilter from '@/components/UI/Select/StatsFilter.vue'
import UISelectWagen from '@/components/UI/Select/Wagen.vue'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'

import { dateStringLocale } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'
import useModal from '@/hooks/useModal'
import usePaginatedApi from '@/hooks/usePaginatedApi'

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))
const ButtonAlertOpmerking = defineAsyncComponent(() => import('@/components/UI/Button/AlertOpmerking.vue'))

const store = useStore()
const api = useApi()
const [openFixedModal] = useModal({ fixed: true })

const d = new Date()

const filters = ref({
  type_datum: 'MAANDELIJKS',
  type: null,
  soort: null,
  day: 1,
  month: d.getMonth() + 1,
  year: d.getFullYear(),
  user_id: null,
  wagen_id: null,
})

const apiData = computed(() => {
  const data = { ...filters.value }

  if (data.type_datum === 'MAANDELIJKS' || data.type_datum === 'JAARLIJKS') delete data.day
  if (data.type_datum === 'JAARLIJKS') delete data.month

  return data
})

const { limit, offset, count, items, getData } = usePaginatedApi('/api/dashboard/kosten/list', apiData, {
  watch: true,
  defaultLimit: 25,
})

const handleEdit = item => {
  openFixedModal('FORM_KOST', { modelValue: clone(unref(item)), callback: getData })
}

const handleToevoegen = () => {
  openFixedModal('FORM_KOST', { callback: getData })
}

const handleVerwijderen = item => {
  openFixedModal('FORM_KOST', { callback: getData })
  const message = `Kost met ID #${item.id} verwijderen?`
  const subtext = 'Deze actie is onomkeerbaar!'

  const callback = () => {
    api('KOST_DELETE', { id: item.id }).then(getData)
  }

  openFixedModal('BEVESTIG_ACTIE', { message, subtext, callback })
}
</script>
